const template = `
<div class="loader-wrapper over-top">
  <div class="loader">
    <div class="stripes">
      <span></span><span></span><span></span><span></span>
    </div>
  </div>
</div>`

export const loader = () => {
  if (document.getElementById('global-loader')) return false

  document.body.appendChild(
    Object.assign(document.createElement('div'), {
      id: 'global-loader',
      innerHTML: template,
    })
  )
}
