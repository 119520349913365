import { registerApplication, start } from 'single-spa'
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout'
import htmlTemplate from './serve-layout.html'
import { loader } from './loader.js'
import pkg from '../package.json'

// Добавление версии стилям для обхода кэша
// В исходники попадет только версия из package.json
const layout = htmlTemplate.replace(/<%=version%>/gm, pkg.version)

const routes = constructRoutes(layout)
const applications = constructApplications({
  routes,
  loadApp: ({ name }) => {
    return Promise.resolve()
      .then(() => {
        loader()
        if (!!~name.indexOf('@vite')) {
          // Если это приложение vite
          return import(/* webpackIgnore: true */ System.resolve(name))
        } else {
          return System.import(name)
        }
      })
      .then((app) => {
        return app
      })
  },
})
const layoutEngine = constructLayoutEngine({ routes, applications })
layoutEngine.isActive()
applications.forEach(registerApplication)
start()
